import { helpers } from '@/utils/helpers.js';

const Columns = [
    {
        label: 'ID',
        name: 'id',
        orderable: true
    },
    {
        label: 'Name',
        name: 'realizador_nome',
        orderable: true
    },
    {
        label: 'Tipo',
        name: 'tipo',
        orderable: true
    },
    {
        label: 'Categoria',
        name: 'categoria',
        orderable: true
    },
    {
        label: 'Data',
        name: 'data',
        orderable: true,
        transform: row => {
            return helpers.formatDate(row.data.data, { dateStyle: 'short' });
        }
        // transform: row => helpers.formatDate(row.created_at, { dateStyle: 'short' })
    },
    {
        label: 'Hora',
        name: 'hora',
        orderable: true
    },
    {
        label: 'Voucher',
        name: 'voucher_id',
        orderable: true
    },
    {
        label: 'Valor',
        name: 'price',
        orderable: true,
        transform: ({ data, name }) => data[name].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
];

export { Columns };
